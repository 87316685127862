// Register IntersectionObserver
const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        dataDelay = parseInt(entry.target.dataset.delay);
        if (entry.intersectionRatio > 0) {
            // Add 'active' class if observation target is inside viewport
            setTimeout(function() {
                entry.target.classList.add("view");
            }, dataDelay);
        } else {
            // Remove 'active' class otherwise
            //entry.target.classList.remove("view");
        }
    });
}, { threshold: 0 });

// Declares what to observe, and observes its properties.
const observerClass = document.querySelectorAll(".animate");

observerClass.forEach((el) => {
    observer.observe(el);
});


jQuery(function ($) {

});